import { notify } from "@kyvg/vue3-notification";
import axios from "axios"

export default {
    namespaced: true,

    state: () => ({
        profile: null
    }),

    mutations: {
        setProfile(state, data) {
            state.profile = data
        }
    },

    actions: {
        async getProfile({ commit }) {
            await axios.get('/profile')
                .then(({ data }) => {
                    commit("setProfile", data)
                })
        },
        async updateProfile({ commit }, form) {
            await axios.put('/profile', form)
                .then(({data}) => {
                    commit("setProfile", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        }
    }
}